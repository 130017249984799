export const builds = {
  macos: 'https://bit.ly/SVKMacAppStore',
  windows:
    'https://drive.google.com/file/d/12KaH4X8MFApB5wAp0WQFYVYn1NTFNEJz/view?usp=sharing',
  windowsLowRes:
    'https://drive.google.com/file/d/1PIhd6Pc6HeMGAj46Oq6ei_WNkiRwHATO/view?usp=sharing',
  ios: 'https://bit.ly/SVKAppStore',
  android: 'https://bit.ly/SVKGooglePlay',
  huawei: 'https://bit.ly/SVKAppGallery',
}
