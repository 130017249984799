import React, { useState, useMemo } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { builds } from '../config/start'
import styles from './school.module.css'
import appStore from '../images/stores/appstore-es.png'
import playStore from '../images/stores/playstore-es.png'
import appGallery from '../images/stores/appgallery-es.png'
import macAppStore from '../images/stores/macappstore-es.png'
import windowsStore from '../images/stores/windowsstore-es.png'
import windowsStoreLow from '../images/stores/windowsstore-low-es.png'

const specs = [
  {
    upper:
      'Mínima versión de Sistema Operativo Windows: Windows 7, Deseable Windows 10.',
    lower:
      'Requerimiento mínimo de hardware para Windows: 4GB de RAM, Procesador iCore 5, DirectX11 instalado, Máximo 5 años de vida activa.',
  },
  {
    upper: 'Mínima versión de Sistema Operativo macOS: 10.13.1',
    lower:
      'Requerimiento mínimo de hardware para macOS: 4GB de RAM, Procesador iCore 5 Máximo 5 años de vida activa.',
  },
  {
    upper: 'Mínima versión de Sistema Operativo Android: 7.0 Nougat',
    lower: 'Requerimiento mínimo de hardware Android: 4GB de RAM.',
  },
  {
    upper: 'Mínima versión de Sistema Operativo iOS: 10.0',
    lower:
      'Requerimiento mínimo de hardware para iOS: iPhone 5, iPad 3ra Generación, iPad Mini, iPad Air, iPad Pro Máximo 3 años de vida activa.',
  },
  {
    upper: 'Mínimo nivel de señal proporcionado por el módem: -53[dBm]',
    lower: 'Frecuencia del Módem: 2.4[GHz]',
  },
]

const SchoolPage = ({ data }) => {
  const { locale, formatMessage } = useIntl()

  const isSpanish = useMemo(() => locale === 'es', [locale])
  const containerSize = useMemo(
    () => (isSpanish ? 'custom-container--large' : ''),
    [isSpanish]
  )
  const paragraphSize = useMemo(() => (isSpanish ? 'w-4/5 mx-auto' : ''), [
    isSpanish,
  ])

  const [formData, setFormData] = useState({
    school: '',
    students: '',
    country: '',
    city: '',
    fullName: '',
    occupation: '',
    email: '',
    phoneNumber: '',
  })

  const onInputChange = (event, field) => {
    const formDataCopy = { ...formData }
    formDataCopy[field] = event.target.value
    setFormData(formDataCopy)
  }

  const renderSpecs = () =>
    specs.map((spec, index) => {
      return (
        <div key={index} className="p-2 text-white text-sm border border-white">
          <div className="mb-4">{spec.upper}</div>
          <div>{spec.lower}</div>
        </div>
      )
    })

  // A String value has to be used in order for puglin to work
  const hasPhoneNumberHelp =
    formatMessage({
      id: 'pages.school.sections.form.fields.phoneNumber.help',
    }) !== 'NULL'

  return (
    <Layout isStartPage>
      <SEO
        lang={formatMessage({ id: 'lang' })}
        title={formatMessage({ id: 'pages.school.title' })}
        description={formatMessage({ id: 'pages.school.description' })}
      />

      <section className={styles.sectionLanding}>
        <div className={`custom-container ${containerSize} py-32`}>
          <div className="badge">
            <Img alt="School badge" fluid={data.badge.childImageSharp.fluid} />
          </div>

          <h1 className="heading text-yellow-light uppercase mt-4">
            <FormattedMessage id="pages.school.title" />
          </h1>

          <p className="mt-8 text-yellow-light text-lg font-semibold">
            <FormattedMessage id="pages.school.sections.landing.title" />
          </p>

          <p className={`${paragraphSize} mt-4 text-white`}>
            <FormattedMessage id="pages.school.sections.landing.p1" />
          </p>

          <p className={`${paragraphSize} mt-4 text-white`}>
            <FormattedMessage id="pages.school.sections.landing.p2" />
          </p>

          {isSpanish ? (
            <>
              <div className="flex text-white mt-12">
                <div className="p-2 w-1/2">
                  <p className="mb-2 mx-auto text-yellow-light">
                    Descargas móviles
                  </p>
                  <div className="flex flex-col items-center md:flex-row md:flex-wrap justify-center">
                    <div className="p-2 w-full sm:w-3/4 md:w-1/2">
                      <a
                        href={builds.android}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={playStore}
                          className="h-auto w-3/4 md:h-12 block mx-auto"
                          alt="Play Store icon"
                        />
                      </a>
                    </div>
                    <div className="p-2 w-full sm:w-3/4 md:w-1/2">
                      <a
                        href={builds.huawei}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={appGallery}
                          className="h-auto w-3/4 md:h-12 block mx-auto"
                          alt="App Gallery icon"
                        />
                      </a>
                    </div>
                    <div className="p-2 w-full sm:w-3/4 md:w-1/2">
                      <a
                        href={builds.ios}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={appStore}
                          className="h-auto w-3/4 md:h-12 block mx-auto"
                          alt="App Store icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="p-2 w-1/2">
                  <p className="mb-2 mx-auto text-yellow-light">
                    Descargas computadoras
                  </p>
                  <div className="flex flex-col items-center md:flex-row md:flex-wrap justify-center">
                    <div className="p-2 w-11/12 sm:w-3/4 md:w-1/2">
                      <a
                        href={builds.windows}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={windowsStore}
                          className="h-auto w-full md:h-12 block mx-auto"
                          alt="Windows Store icon"
                        />
                      </a>
                    </div>
                    <div className="p-2 w-11/12 sm:w-3/4 md:w-1/2">
                      <a
                        href={builds.windowsLowRes}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={windowsStoreLow}
                          className="h-auto w-full md:h-12 block mx-auto"
                          alt="Windows Store Low Res icon"
                        />
                      </a>
                    </div>
                    <div className="p-2 w-11/12 sm:w-3/4 md:w-1/2">
                      <a
                        href={builds.macos}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={macAppStore}
                          className="h-auto w-full md:h-12 block mx-auto"
                          alt="Mac App Store icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center mt-8">
                <p className="mx-auto text-yellow-light">Descargas manuales</p>
                <div className="flex flex-col sm:flex-row items-center justify-center">
                  <a
                    href="/start/guia-partidas-remotas.pdf"
                    className="inline-block p-2 underline text-white"
                    download
                  >
                    Manual de partidas remotas
                  </a>

                  <a
                    href="/start/guia-instalacion-windows.pdf"
                    className="inline-block p-2 underline text-white"
                    download
                  >
                    Guía de instalación Windows
                  </a>
                </div>
              </div>
            </>
          ) : (
            <button className="btn btn--inverted mt-8">
              <FormattedMessage id="pages.school.sections.landing.button" />
            </button>
          )}
        </div>

        <div className="relative custom-container custom-container--extralarge">
          <div className={styles.teacher}>
            <Img alt="Teacher" fluid={data.teacher.childImageSharp.fluid} />
          </div>
        </div>
      </section>

      {isSpanish ? (
        <section className="bg-purple-start">
          <div className={`custom-container ${containerSize} py-24`}>
            <h2 className="heading text-yellow-light">
              ¡La aventura apenas comienza!
            </h2>

            <p className={`${paragraphSize} text-white`}>
              Descarga Start Vikidz hoy mismo, es la herramienta adecuada para
              escuelas que desean incorporarse a la vanguardia educativa ya que
              ofrece contenido pedagógico de alta calidad y alineado a los
              programas oficiales de primero a sexto de primaria.
            </p>

            <div className="mt-8 relative">
              <div className="w-2/5 mx-auto -mb-12">
                <Img
                  alt="Vikidz Start Logo"
                  fluid={data.startLogo.childImageSharp.fluid}
                />
              </div>

              <div className={styles.app}>
                <Img
                  alt="Vikidz Start App Icon"
                  fluid={data.startApp.childImageSharp.fluid}
                />
              </div>

              <div className="-mb-8">
                <Img alt="" fluid={data.startDevices.childImageSharp.fluid} />
              </div>
            </div>

            <div className={styles.specs}>{renderSpecs()}</div>
          </div>
        </section>
      ) : (
        <section className="bg-blue-darkest">
          <div className="custom-container py-24">
            <h2 className="heading text-yellow-light">
              <FormattedMessage id="pages.school.sections.form.title" />
            </h2>

            <div className="w-2/3 mx-auto mb-8">
              <p className="text-white">
                <FormattedMessage id="pages.school.sections.form.description" />
              </p>
            </div>

            <form
              name="demo"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="demo" />

              <div className="hidden">
                <label>
                  Don’t fill this out if you're human:
                  <input type="hidden" name="bot-field" />
                </label>
              </div>

              <div className="form-field">
                <label className="form-label" htmlFor="school">
                  <FormattedMessage id="pages.school.sections.form.fields.school.label" />
                </label>
                <span className="form-label-help block">
                  <FormattedMessage id="pages.school.sections.form.fields.school.help" />
                </span>
                <input
                  id="school"
                  name="school"
                  type="text"
                  className="form-control mt-2"
                  onChange={event => onInputChange(event, 'school')}
                  value={formData.school}
                />
              </div>

              <div className="flex flex-wrap md:flex-no-wrap">
                <div className="form-field w-full md:w-1/2 md:mr-4">
                  <label className="form-label" htmlFor="students">
                    <FormattedMessage id="pages.school.sections.form.fields.students.label" />
                  </label>
                  <span className="form-label-help block">
                    <FormattedMessage id="pages.school.sections.form.fields.students.help" />
                  </span>
                  <input
                    id="students"
                    name="students"
                    type="number"
                    className="form-control mt-2"
                    onChange={event => onInputChange(event, 'students')}
                    value={formData.students}
                  />
                </div>

                <div className="form-field w-full md:w-1/4 md:mr-4 flex flex-col justify-between">
                  <label className="form-label" htmlFor="country">
                    <FormattedMessage id="pages.school.sections.form.fields.country" />
                  </label>
                  <input
                    id="country"
                    name="country"
                    type="text"
                    className="form-control mt-2"
                    onChange={event => onInputChange(event, 'country')}
                    value={formData.country}
                  />
                </div>

                <div className="form-field w-full md:w-1/4 flex flex-col justify-between">
                  <label className="form-label" htmlFor="city">
                    <FormattedMessage id="pages.school.sections.form.fields.city" />
                  </label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    className="form-control mt-2"
                    onChange={event => onInputChange(event, 'city')}
                    value={formData.city}
                  />
                </div>
              </div>

              <div className="flex flex-wrap md:flex-no-wrap">
                <div className="form-field w-full md:w-3/5 md:mr-4">
                  <label className="form-label" htmlFor="fullName">
                    <FormattedMessage id="pages.school.sections.form.fields.fullName" />
                  </label>
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    className="form-control mt-2"
                    onChange={event => onInputChange(event, 'fullName')}
                    value={formData.fullName}
                  />
                </div>

                <div className="form-field w-full md:w-2/5">
                  <label className="form-label" htmlFor="occupation">
                    <FormattedMessage id="pages.school.sections.form.fields.occupation" />
                  </label>
                  <input
                    id="occupation"
                    name="occupation"
                    type="text"
                    className="form-control mt-2"
                    onChange={event => onInputChange(event, 'occupation')}
                    value={formData.occupation}
                  />
                </div>
              </div>

              <div className="flex flex-wrap md:flex-no-wrap">
                <div className="form-field w-full md:w-3/5 md:mr-4 flex flex-col justify-between">
                  <label className="form-label" htmlFor="email">
                    <FormattedMessage id="pages.school.sections.form.fields.email" />
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="form-control mt-2"
                    onChange={event => onInputChange(event, 'email')}
                    value={formData.email}
                  />
                </div>

                <div className="form-field w-full md:w-2/5">
                  <label className="form-label" htmlFor="phoneNumber">
                    <FormattedMessage id="pages.school.sections.form.fields.phoneNumber.label" />
                  </label>
                  {hasPhoneNumberHelp && (
                    <span className="form-label-help block">
                      <FormattedMessage id="pages.school.sections.form.fields.phoneNumber.help" />
                    </span>
                  )}
                  <input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    className="form-control mt-2"
                    onChange={event => onInputChange(event, 'phoneNumber')}
                    value={formData.phoneNumber}
                  />
                </div>
              </div>

              <button type="submit" className="btn mt-2">
                <FormattedMessage id="pages.school.sections.form.fields.button" />
              </button>
            </form>
          </div>
        </section>
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    badge: file(relativePath: { eq: "badges/school-color.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teacher: file(relativePath: { eq: "school/teacher-start.png" }) {
      childImageSharp {
        fluid(maxHeight: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    startLogo: file(relativePath: { eq: "start/logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    startApp: file(relativePath: { eq: "start/app.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    startDevices: file(relativePath: { eq: "start/devices.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default SchoolPage
